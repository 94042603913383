import React, { FC, MouseEvent, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';

import LoadingDots from './loading/loading-dots.components';

type ButtonSize = 'sm' | 'md';
type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'dark'
  | 'light'
  | 'ghost'
  | 'danger'
  | 'danger-outline'
  | 'free-tools'
  | 'unstyled'
  | 'icon';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  rounded?: 'md' | 'full';
  isDisabled?: boolean;
  isLoading?: boolean;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}
const Button: FC<ButtonProps> = ({
  className,
  children,
  variant = 'primary',
  size = 'md',
  rounded = 'md',
  isDisabled = false,
  isLoading = false,
  handleClick,
  ...rest
}) => {
  return (
    <button
      data-testid="button"
      className={cn(
        'focus-visible:outline-main relative flex w-full items-center justify-center transition-all focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
        {
          'bg-main border-main border text-white hover:enabled:opacity-90':
            variant === 'primary',
          'border border-[rgba(0,0,0,0.1)] bg-cyan-50 text-black hover:enabled:opacity-90':
            variant === 'secondary',
          'border border-black bg-black text-white hover:enabled:opacity-90':
            variant === 'dark',
          'border border-red-600 bg-red-600 text-white hover:enabled:border-red-500 hover:enabled:bg-red-500':
            variant === 'danger',
          'border border-gray-700 bg-white text-black hover:enabled:border-red-700 hover:enabled:text-red-700':
            variant === 'danger-outline',
          'border border-gray-300 bg-white text-gray-700 hover:enabled:bg-gray-50':
            variant === 'light',
          'border border-gray-300 bg-gray-100 text-gray-700 hover:enabled:border-gray-400 hover:enabled:text-gray-800':
            variant === 'ghost',
          'border border-black bg-black text-white hover:enabled:border-[#75EAF1] hover:enabled:bg-[#75EAF1] hover:enabled:text-black hover:enabled:opacity-90':
            variant === 'free-tools',
          'unstyled-button': variant === 'unstyled',

          'px-4 py-1.5 text-sm font-medium shadow-sm sm:leading-[22px]':
            size === 'md',
          'px-3 py-1.5 text-xs font-medium shadow-sm': size === 'sm',
          'rounded-md': rounded === 'md',
          'rounded-3xl': rounded === 'full',
          'cursor-not-allowed opacity-70': isDisabled,

          [className]: !!className,
        },
      )}
      disabled={isDisabled || isLoading}
      onClick={handleClick}
      {...rest}
    >
      <span
        data-testid="button-text"
        className={cn('flex items-center justify-center', {
          ['opacity-0']: isLoading,
          ['opacity-100']: !isLoading,
        })}
      >
        {children}
      </span>

      {isLoading ? (
        <div
          data-testid="button-loading"
          className={cn('absolute inset-0 flex items-center justify-center', {
            'bg-main': variant === 'primary',
            'bg-cyan-50': variant === 'secondary',
            'bg-black': variant === 'dark' || variant === 'free-tools',
            'bg-red-600': variant === 'danger',
            'bg-white': variant === 'danger-outline' || variant === 'light',
            'bg-gray-100': variant === 'ghost',
            'rounded-md': rounded === 'md',
            'rounded-3xl': rounded === 'full',
          })}
        >
          <LoadingDots
            size={size === 'md' ? 20 : 16}
            fill={
              variant === 'primary' ||
              variant === 'dark' ||
              variant === 'danger' ||
              variant === 'free-tools'
                ? 'white'
                : 'black'
            }
          />
        </div>
      ) : null}
    </button>
  );
};

export default Button;
